import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalComponent from './ModalComponent';
import './AdminPage.css'; // Import your CSS file for styling
import { config } from './config';

const AdminPage = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchRestaurants();
  }, []);

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}restaurants`);
      setRestaurants(response.data);
    } catch (error) {
      console.error('Error fetching restaurants', error);
    }
  };

  const deleteRestaurant = async (id) => {
    try {
      await axios.delete(`${config.BASE_URL}restaurants/${id}`);
      fetchRestaurants();
    } catch (error) {
      console.error('Error deleting restaurant', error);
    }
  };
  const toggleAccess = async (id, haveAccess) => {
    try {
      await axios.put(`${config.BASE_URL}restaurants/${id}/access`, { have_access: !haveAccess });
      fetchRestaurants();
    } catch (error) {
      console.error('Error toggling access', error);
    }
  };
  const toggleApproval = async (id, is_approved) => {
    try {
      await axios.put(`${config.BASE_URL}restaurants/${id}/approval`, { is_approved: !is_approved });
      fetchRestaurants();
    } catch (error) {
      console.error('Error toggling access', error);
    }
  };
  const openModal = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRestaurant(null);
  };

  const saveRestaurantCommission = async (restaurant, i) => {
    try {
      const response = await axios.put(`${config.BASE_URL}restaurants/${restaurant._id}/commission`, {
        commission: restaurant.commission
      });
      alert(response.data.message)
    } catch (error) {
      console.error('Error fetching restaurants', error);
    }
  }

  return (
    <div className="admin-page">
      <h1>Restaurant Page</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
            <th>Commission</th>
            <th>Access</th>
            <th>Approved</th>
          </tr>
        </thead>
        <tbody>
          {restaurants.map((restaurant, i) => (
            <tr key={restaurant._id}>
              <td>{restaurant.name}</td>
              <td>{restaurant.email}</td>
              <td>
                <button onClick={() => openModal(restaurant)}>View Details</button>
                <button onClick={() => deleteRestaurant(restaurant._id)}>Delete</button>
              </td>
              <td>
                <div>
                  <input type='number'
                    onChange={(e) => {
                      let value = +e.target.value;
                      if (isNaN(value)) {
                        return
                      }
                      if (value > 100) {
                        value = 100;
                      } else if (value < 0) {
                        value = 0
                      }
                      restaurant.commission = value
                      setRestaurants([...restaurants])
                    }}
                    value={restaurant.commission} placeholder='Commission %' />
                  %
                </div>
                <button onClick={() => saveRestaurantCommission(restaurant, i)}>Save</button>
              </td>
              <td>
                <p className={restaurant.have_access ? 'access-yes' : 'access-no'}>
                  {restaurant.have_access ? 'Yes' : 'No'}
                </p>
                <button onClick={() => toggleAccess(restaurant._id, restaurant.have_access)}>
                  {restaurant.have_access ? 'Revoke Access' : 'Grant Access'}
                </button>
              </td>
              <td>
                <p className={restaurant.is_approved ? 'access-yes' : 'access-no'}>
                  {restaurant.is_approved ? 'Yes' : 'No'}
                </p>
                <button onClick={() => toggleApproval(restaurant._id, restaurant.is_approved)}>
                  {restaurant.is_approved ? 'Revoke Approval' : 'Approve'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRestaurant && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          data={selectedRestaurant}
        />
      )}
    </div>
  );
};

export default AdminPage;
